<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="group_1 flex-col">
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="./assets/img/title.png"
        />
        <div class="block_1 flex-col">
          <div class="box_2 flex-row">
            <el-input
              placeholder="请输入您的账号"
              v-model="form.username"
              @keyup.enter="handlerlogin"
              ><template #prepend>
                <img
                  referrerpolicy="no-referrer"
                  src="./assets/img/user.png" /></template
            ></el-input>
          </div>
          <div class="box_3 flex-row">
            <el-input
              placeholder="请输入您的密码"
              type="password"
              v-model="form.password"
              @keyup.enter="handlerlogin"
              ><template #prepend>
                <img
                  referrerpolicy="no-referrer"
                  src="./assets/img/pass.png" /></template
            ></el-input>
          </div>
          <div class="box_4 flex-row">
            <el-checkbox v-model="rememberPass" label="记住密码" />
            <!-- <div class="box_5 flex-col"></div>
            <span class="text_1">记住密码</span> -->
            <!-- <span class="text_2">忘记密码</span> -->
          </div>
          <div class="text-wrapper_1 flex-col" @click="handlerlogin">
            <span class="text_3">{{
              logining ? '登录中...' : '立 即 登 录'
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, onMounted } from 'vue'
import router from '@/router'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import md5 from 'blueimp-md5'
import { encrypt, decrypt } from '@/utitls/crypto'
import { isNull } from '@/utitls/validate.js'
const store = useStore()
const logining = ref(false)
const rememberPass = ref(false)
const form = ref({
  username: '',
  password: ''
})
onMounted(() => {
  store.dispatch('login/userLogout')
  if (!isNull(store.state.login.rememberInfo)) {
    let rememberInfos = store.state.login.rememberInfo
    form.value.username = rememberInfos.account
    rememberPass.value = rememberInfos.rememberPass
    if (rememberInfos.rememberPass && rememberInfos.passWord != '')
      form.value.password = decrypt(rememberInfos.passWord)
    else form.value.password = ''
  }
})
const handlerlogin = () => {
  if (!form.value.username || !form.value.password) {
    ElMessage.warning({
      message: '账号或密码不能为空',
      type: 'warning'
    })
  } else {
    logining.value = true
    store
      .dispatch('login/userLogin', {
        account: form.value.username,
        password: md5(form.value.password),
        clientType: 'PC端'
      })
      .then((res) => {
        if (res) {
          store.dispatch(
            'login/setRememberInfos',
            JSON.stringify({
              rememberPass: rememberPass.value,
              account: form.value.username,
              passWord: rememberPass.value ? encrypt(form.value.password) : ''
            })
          )
          ElMessage({
            showClose: true,
            type: 'success',
            message: '登录成功',
            duration: 1000
          })
          logining.value = false
          router.push('/home')
        }
      })
      .catch((err) => {
        logining.value = false
        console.log(err)
        ElMessage({
          showClose: true,
          type: 'error',
          message: err,
          duration: 1000
        })
      })
  }
}
</script>

<style scoped lang="scss" src="./assets/index.scss" />
